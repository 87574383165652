/* Switzer Regular */

@font-face {
  font-family: 'Switzer';
  src: url('/fonts/Switzer-Regular.woff2') format('woff2'),
       url('/fonts/Switzer-Regular.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('/fonts/Switzer-Italic.woff2') format('woff2'),
       url('/fonts/Switzer-Italic.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

/* Switzer Medium */

@font-face {
  font-family: 'Switzer';
  src: url('/fonts/Switzer-Medium.woff2') format('woff2'),
       url('/fonts/Switzer-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('/fonts/Switzer-MediumItalic.woff2') format('woff2'),
       url('/fonts/Switzer-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

/* Switzer Semibold */

@font-face {
  font-family: 'Switzer';
  src: url('/fonts/Switzer-Semibold.woff2') format('woff2'),
       url('/fonts/Switzer-Semibold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Switzer';
  src: url('/fonts/Switzer-SemiboldItalic.woff2') format('woff2'),
       url('/fonts/Switzer-SemiboldItalic.woff') format('woff');
  font-weight: 600;
  font-display: swap;
  font-style: italic;
}

/* Sentient Light */

@font-face {
  font-family: 'Sentient';
  src: url('/fonts/Sentient-Light.woff2') format('woff2'),
       url('/fonts/Sentient-Light.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient';
  src: url('/fonts/Sentient-Light.woff2') format('woff2'),
       url('/fonts/Sentient-Light.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient';
  src: url('/fonts/Sentient-LightItalic.woff2') format('woff2'),
       url('/fonts/Sentient-LightItalic.woff') format('woff');
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Sentient';
  src: url('/fonts/Sentient-LightItalic.woff2') format('woff2'),
       url('/fonts/Sentient-LightItalic.woff') format('woff');
  font-weight: normal;
  font-display: swap;
  font-style: italic;
}

/* Sentient Medium */

@font-face {
  font-family: 'Sentient';
  src: url('/fonts/Sentient-Medium.woff2') format('woff2'),
       url('/fonts/Sentient-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Sentient';
  src: url('/fonts/Sentient-MediumItalic.woff2') format('woff2'),
       url('/fonts/Sentient-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}
